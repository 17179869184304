import { combineReducers } from 'redux';
import appReducer from './reducers/appReducer';
import userReducer from './reducers/userReducer';
import exampleTicketReducer from './reducers/exampleTicketReducer';
import profilKlettReducer from './reducers/profilKlettReducer';
import costBookReducer from './reducers/costBookReducer';
import surveyReducer from './reducers/surveyReducer';
import projectReducer from './reducers/projectReducer';
import dmsReducer from './reducers/dmsReducer';
import customerReducer from './reducers/customerReducer';

export default combineReducers({
  app: appReducer,
  user: userReducer,
  profilKlett: profilKlettReducer,
  exampleTicket: exampleTicketReducer,
  costBook: costBookReducer,
  survey: surveyReducer,
  project: projectReducer,
  dms: dmsReducer,
  customer: customerReducer
});

import React from 'react';
import * as palette from '../style/Variables';

// HOME
// import Home from '../containers/Main/home/Home';
const Home = React.lazy(() => import('../containers/Main/home/Home'));
// HELP
// const Help = React.lazy(() => import('../containers/Main/help-management/Help'));
// ADMIN APPLICATION
const ApplicationManagement = React.lazy(() => import('../containers/Main/application-management/ApplicationManagement'));
const ApplicationDashboard = React.lazy(() => import('../containers/Main/application-management/dashboard/ApplicationDashboard'));
const Application = React.lazy(() => import('../containers/Main/application-management/application/Application'));
const ApplicationRoleAndPermissions = React.lazy(() => import('../containers/Main/application-management/application/role-permissions/ApplicationRoleAndPermissions'));
const ApplicationRoleAndPermissionsBackend = React.lazy(() => import('../containers/Main/application-management/application/role-permissions/ApplicationRoleAndPermissionsBackend'));
// ADMIN ORGANIZATION
const OrganizationManagement = React.lazy(() => import('../containers/Main/organization-management/Organization'));
const OrganizationDashboard = React.lazy(() => import('../containers/Main/organization-management/dashboard/OrganizationDashboard'));
const ApplicationOrganizations = React.lazy(() => import('../containers/Main/organization-management/organizations/ApplicationOrganizations'));
const OrganizationApplicationRoles = React.lazy(() => import('../containers/Main/organization-management/organizations/application-roles/OrganizationApplicationRoles'));
// SHOW ELEMENTS
const ShowElements = React.lazy(() => import('../containers/Main/show-elements/ShowElements'));


// CLIENT
// SETTINGS
const Settings = React.lazy(() => import('../containers/Client/settings/Settings'));
const UserTranslation = React.lazy(() => import('../containers/Client/settings/UserTranslation'));
// NOTIFICATIONS
// const Notifications = React.lazy(() => import('../containers/Client/notifications/Notifications'));
// ORGANIZATION MANAGEMENT
const OrganizationClient = React.lazy(() => import('../containers/Client/organization/OrganizationClient'));
const OrganizationDashboardClient = React.lazy(() => import('../containers/Client/organization/dashboard/OrganizationDashboardClient'));
const GroupsAndUsers = React.lazy(() => import('../containers/Client/organization/groups-users/GroupsAndUsers'));
const GroupsAndRoles = React.lazy(() => import('../containers/Client/organization/groups-roles/GroupsAndRoles'));
const GroupsHierarchy = React.lazy(() => import('../containers/Client/organization/groups-hierarchy/GroupsHierarchy'));
// EXAMPLE TICKET
const ClientExampleTicket = React.lazy(() => import('../containers/Client/client-example/ClientExampleTicket'));
const ExampleTicketDashboard = React.lazy(() => import('../containers/Client/client-example/dashboard/ExampleTicketDashboard'));
const ExampleTicketAll = React.lazy(() => import('../containers/Client/client-example/all-tickets/ExampleTicketAll'));
const ExampleTicketCalendar = React.lazy(() => import('../containers/Client/client-example/calendar/ExampleTicketCalendar'));
const ExampleTicketClients = React.lazy(() => import('../containers/Client/client-example/clients/ExampleTicketClients'));
// COST BOOK
const CostBook = React.lazy(() => import('../containers/Client/cost-book/CostBook'));
const CostBookDashboard = React.lazy(() => import('../containers/Client/cost-book/dashboard/Dashboard'));
const CostBookContractualCost = React.lazy(() => import('../containers/Client/cost-book/contractual-cost/ContractualCost'));
const CostBookContractualCostEdit = React.lazy(() => import('../containers/Client/cost-book/cost-books/CostBookEdit'));
const CostBookConstructionWorks = React.lazy(() => import('../containers/Client/cost-book/construction-works/ConstructionWorks'));
// const CostBookNewWorks = React.lazy(() => import('../containers/Client/cost-book/new-works/NewWorks'));
const CostBookNewWorks = React.lazy(() => import('../containers/Client/cost-book/new-works/NewWorksArhitekti'));  // ARHITEKTI
const CostBookCostBooks = React.lazy(() => import('../containers/Client/cost-book/cost-books/CostBooks'));
const CostBookCostBooksView = React.lazy(() => import('../containers/Client/cost-book/cost-books/CostBooksView'));
const CostBookDeliveredView = React.lazy(() => import('../containers/Client/cost-book/cost-books/CostBookDeliveredView'));

const CostBookPredefinedContractualCost = React.lazy(() => import('../containers/Client/cost-book/predefined-contractual-cost/PredefinedContractualCost'));
const PredefinedCostBook = React.lazy(() => import('../containers/Client/cost-book/predefined-cost-book/PredefinedCostBook'));
const PredefinedCostBooks = React.lazy(() => import('../containers/Client/cost-book/predefined-cost-books/PredefinedCostBooks'));
const PredefinedCostBooksView = React.lazy(() => import('../containers/Client/cost-book/predefined-cost-books/PredefinedCostBooksView'));
const DeliveredCostBookView = React.lazy(() => import('../containers/Client/cost-book/predefined-cost-books/DeliveredCostBookView'));
// COSTBOOK GUEST EDIT
const CostBookGuestPreEdit = React.lazy(() => import('../containers/Client/cost-book/contractual-cost/ContractualCostREFTEMPGUEST'));

// SURVEYS
// const Surveys = React.lazy(() => import('../containers/Client/surveys/Survey'));
// const SurveysDashboard = React.lazy(() => import('../containers/Client/surveys/dashboard/Dashboard'));
// const SurveysSurveys = React.lazy(() => import('../containers/Client/surveys/surveys/Surveys'));
// //const SurveyAnswersEdit = React.lazy(() => import('../containers/Client/surveys/surveys/answers/EditAnswers'));
// //const SurveyAnswersView = React.lazy(() => import('../containers/Client/surveys/surveys/answers/ViewAnswers'));

// const EditPersonal = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/EditPersonal'));
// const EditContact = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/EditContact'));
// const EditInterest = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/EditInterest'));
// const EditJob = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/EditJob'));
// const EditMeeting = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/EditMeeting'));
// const ViewPersonal = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/ViewPersonal'));
// const ViewContact = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/ViewContact'));
// const ViewInterest = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/ViewInterest'));
// const ViewJob = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/ViewJob'));
// const ViewMeeting = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/ViewMeeting'));
// const AnswersPersonal = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/AnswersPersonal'));
// const AnswersContact = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/AnswersContact'));
// const AnswersInterest = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/AnswersInterest'));
// const AnswersJob = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/AnswersJob'));
// const AnswersMeeting = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/AnswersMeeting'));
// const AnswersEditPersonal = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/AnswersEditPersonal'));
// const AnswersEditContact = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/AnswersEditContact'));
// const AnswersEditInterest = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/AnswersEditInterest'));
// const AnswersEditJob = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/AnswersEditJob'));
// const AnswersEditMeeting = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/AnswersEditMeeting'));

// const EditAllInOne = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/EditAllInOne'));
// const DragAllInOne = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/DragAllInOne'));
// const ViewAllInOne = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/ViewAllInOne'));
// const AnswersAllInOne = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/AnswersAllInOne'));
// const AnswersEditAllInOne = React.lazy(() => import('../containers/Client/surveys/surveys/EditViewAnswers/AnswersEditAllInOne'));

// const AddNewSurvey = React.lazy(() => import('../containers/Client/surveys/surveys/AddNewSurvey'));
// const EditSurvey = React.lazy(() => import('../containers/Client/surveys/surveys/EditSurvey'));
// const DragSurvey = React.lazy(() => import('../containers/Client/surveys/surveys/DragSurvey'));
// const ViewSurvey = React.lazy(() => import('../containers/Client/surveys/surveys/ViewSurvey'));
// const FillSurvey = React.lazy(() => import('../containers/Client/surveys/surveys/FillSurvey'));
// const AnswersSurvey = React.lazy(() => import('../containers/Client/surveys/surveys/AnswersSurvey'));

// DMS
const DMS = React.lazy(() => import('../containers/Client/dms/DMS'));

// PROJECTS
const Projects = React.lazy(() => import('../containers/Client/projects/Projects'));
const ProjectsConfigurator = React.lazy(() => import('../containers/Client/projects/pages/Configurator'));
const ProjectsGroup = React.lazy(() => import('../containers/Client/projects/pages/ProjectsGroup'));
const ProjectsNotification = React.lazy(() => import('../containers/Client/projects/pages/Notification'));
// const ProjectsTask = React.lazy(() => import('../containers/Client/projects/pages/Task'));
const ProjectsActivity = React.lazy(() => import('../containers/Client/projects/pages/Activity'));
const ProjectsTemplate = React.lazy(() => import('../containers/Client/projects/pages/Template'));

// PROFIL KLETT - ADMIN
// OFFERS
// const Offers = React.lazy(() => import('../containers/Client/offers/Offers'));
// const NewOffer = React.lazy(() => import('../containers/Client/offers/new-offer/NewOffer'));
// CUSTOMERS
// const CustOmers = React.lazy(() => import('../containers/Client/customers/CustOmers'));
// PRODUCTS
// const Products = React.lazy(() => import('../containers/Client/products/Products'));
// const ProductsLocal = React.lazy(() => import('../containers/Client/products/products-local/ProductsLocal'));
// GRATIS OFFERS
// const OrdersGratis = React.lazy(() => import('../containers/Client/orders-gratis/OrdersGratis'));

// PROFIL KLETT - BACKOFFICE
const CustomersOffice = React.lazy(() => import('../containers/Client/customers-office/CustOmersOffice'));
const CustomersOfficeLink = React.lazy(() => import('../containers/Client/customers-office/CustomersOfficeLink'));
// PROFIL KLETT - KLIJENTI
const ClientOrders = React.lazy(() => import('../containers/Client/orders/ClientOrders'));
const NewOrder = React.lazy(() => import('../containers/Client/orders/new-order/NewOrder'));


// constants for security reason
const HOME = '/'
// const HELP = '/help-management'
const APPLICATION = '/application-management'

// key is id value because they render in array
const routesAndComponents = [
    // HOME
    {
        id: 1,
        text: 'Home',
        name: 'Home',
        path: HOME,
        color: 'primary',
        icon: palette.home2,
        component: <Home path={HOME} key={1} />,
        parentId: null,
        showRoute: false,
        showMobileRoute: true,
        permission: 'home',
        dropdown: false,
        sortOrder: 1,
        singleComponent: true
    },
    // HELP
    // {
    //     id: 15,
    //     text: 'Help',
    //     name: 'Help',
    //     path: HELP,
    //     icon: palette.help,
    //     component: <Help path={HELP} key={15} />,
    //     parentId: null,
    //     showRoute: false,
    //     showMobileRoute: true,
    //     permission: 'help',
    //     dropdown: false,
    //     sortOrder: null
    // },
    // NOTIFICATIONS
    // {
    //     id: 14,
    //     text: 'Notifications',
    //     name: 'notifications',
    //     path: '/notifications',
    //     icon: palette.bell,
    //     component: <Notifications path={'/notifications'} key={14} />,
    //     parentId: null,
    //     showRoute: false,
    //     showMobileRoute: true,
    //     permission: 'notifications',
    //     dropdown: false,
    //     sortOrder: null
    // },
    // SETTINGS
    {
        id: 16,
        text: 'Settings',
        name: 'Settings',
        path: '/settings',
        icon: palette.settings,
        component: <Settings path={'/settings'} key={16} />,
        parentId: null,
        showRoute: false,
        showMobileRoute: true,
        permission: 'settings',
        dropdown: false,
        sortOrder: null
    },
    {
        id: 17,
        text: 'Translation',
        name: 'UserTranslation',
        path: '/settings/translation',
        icon: palette.language,
        component: <UserTranslation path={'/settings/translation'} key={17} />,
        parentId: null,
        showRoute: false,
        showMobileRoute: false,
        permission: 'allow_translate',
        dropdown: false,
        sortOrder: null
    },
    // ADMIN APPLICATION
    {
        id: 2,
        text: 'Admin Application',
        name: 'Application-management',
        path: APPLICATION,
        color: 'grey',
        icon: palette.flatApp,
        component: <ApplicationManagement path={APPLICATION} key={2} />,
        parentId: null,
        showRoute: true,
        showMobileRoute: true,
        permission: 'superadmin_application_management',
        dropdown: false,
        sortOrder: 2
    },
    {
        id: 3,
        text: 'Dashboard',
        name: 'ApplicationDashboard',
        path: '/application-management/dashboard',
        color: 'grey',
        icon: palette.flatDashboard,
        component: <ApplicationDashboard path="/application-management/dashboard" key={3} />,
        parentId: 2,
        showRoute: true,
        showMobileRoute: false,
        permission: 'superadmin_application_management',
        dropdown: false,
        sortOrder: 21
    },
    {
        id: 4,
        text: 'Applications',
        name: 'Application',
        path: '/application-management/application',
        color: 'grey',
        icon: palette.flatApp,
        component: <Application path="/application-management/application" key={4} />,
        parentId: 2,
        showRoute: true,
        showMobileRoute: false,
        permission: 'superadmin_application_management',
        dropdown: false,
        sortOrder: 22
    },
    {
        id: 5,
        text: 'Roles & Permissions',
        name: 'ApplicationRoleAndPermissions',
        path: '/application-management/application/role-permissions',
        color: 'grey',
        icon: palette.flatApp,
        component: <ApplicationRoleAndPermissions path="/application-management/application/role-permissions" key={5} />,
        parentId: 2, // null - dont show in menu
        showRoute: false,
        showMobileRoute: false,
        permission: 'superadmin_application_management',
        dropdown: false,
        sortOrder: null
    },
    {
        id: 6,
        text: 'Backend Permission Mapping',
        name: 'ApplicationRoleAndPermissionsBackend',
        path: '/application-management/application/role-permissions/backend',
        color: 'grey',
        icon: palette.flatApp,
        component: <ApplicationRoleAndPermissionsBackend path="/application-management/application/role-permissions/backend" key={6} />,
        parentId: 2,
        showRoute: false,
        showMobileRoute: false,
        permission: 'superadmin_application_management',
        dropdown: false,
        sortOrder: null
    },
    // ADMIN ORGANIZATION
    {
        id: 7,
        text: 'Admin Organization',
        name: 'Organization-management',
        path: '/organization-management',
        color: 'ocher',
        icon: palette.flatNetwork,
        component: <OrganizationManagement path="/organization-management" key={7} />,
        parentId: null,
        showRoute: true,
        showMobileRoute: true,
        permission: 'superadmin_organization_management',
        dropdown: false,
        sortOrder: 3
    },
    {
        id: 8,
        text: 'Dashboard',
        name: 'OrganizationDashboard',
        path: '/organization-management/dashboard',
        color: 'ocher',
        icon: palette.flatDashboard,
        component: <OrganizationDashboard path="/organization-management/dashboard" key={8} />,
        parentId: 7,
        showRoute: true,
        showMobileRoute: false,
        permission: 'superadmin_organization_management',
        dropdown: false,
        sortOrder: 31
    },
    {
        id: 9,
        text: 'Organizations',
        name: 'ApplicationOrganizations',
        path: '/organization-management/organizations',
        color: 'ocher',
        icon: palette.flatNetwork,
        component: <ApplicationOrganizations path="/organization-management/organizations" key={9} />,
        parentId: 7,
        showRoute: true,
        showMobileRoute: false,
        permission: 'superadmin_organization_management',
        dropdown: false,
        sortOrder: 32
    },
    {
        id: 10,
        text: 'Application & Roles',
        name: 'OrganizationApplicationRoles',
        path: '/organization-management/organizations/application-roles',
        color: 'ocher',
        icon: palette.gavel,
        component: <OrganizationApplicationRoles path="/organization-management/organizations/application-roles" key={10} />,
        // parentId: null, // dont show in menu
        parentId: 7,
        showRoute: false,
        showMobileRoute: false,
        permission: 'superadmin_organization_management',
        dropdown: false,
        sortOrder: null
    },
    // SHOW ELEMENTS
    {
        id: 16,
        text: 'Show Elements',
        name: 'ShowElements',
        path: '/show-elements',
        color: 'pink',
        icon: palette.flatEye,
        component: <ShowElements path="/show-elements" key={16} />,
        parentId: null,
        showRoute: true,
        showMobileRoute: true,
        permission: 'show_elements',
        dropdown: false,
        sortOrder: 5,
        singleComponent: true
    },


    // CLIENT
    // ORGANIZATION MANAGEMENT
    {
        id: 20,
        text: 'Organization',
        name: 'Organization',
        path: '/client-organization',
        color: 'blue',
        icon: palette.flatNetwork,
        component: <OrganizationClient path="/client-organization" key={20} />,
        parentId: null,
        showRoute: true,
        showMobileRoute: true,
        permission: 'client_organization_management',
        dropdown: false,
        sortOrder: 10
    },
    {
        id: 21,
        text: 'Dashboard',
        name: 'OrganizationDashboardClient',
        path: '/client-organization/dashboard',
        color: 'blue',
        icon: palette.flatDashboard,
        component: <OrganizationDashboardClient path="/client-organization/dashboard" key={21} />,
        parentId: 20,
        showRoute: true,
        showMobileRoute: false,
        permission: 'client_organization_dashboard',
        dropdown: false,
        sortOrder: 101
    },
    {
        id: 22,
        text: 'Groups And Users',
        name: 'GroupsAndUsers',
        path: '/client-organization/groups-users',
        color: 'blue',
        icon: palette.flatUsers,
        component: <GroupsAndUsers path="/client-organization/groups-users" key={22} />,
        parentId: 20,
        showRoute: true,
        showMobileRoute: false,
        permission: 'client_organization_groups_users',
        dropdown: false,
        sortOrder: 102
    },
    {
        id: 23,
        text: 'Groups And Roles',
        name: 'GroupsAndRoles',
        path: '/client-organization/groups-roles',
        color: 'blue',
        icon: palette.flatNetwork,
        component: <GroupsAndRoles path="/client-organization/groups-roles" key={23} />,
        parentId: 20,
        showRoute: true,
        showMobileRoute: false,
        permission: 'client_organization_groups_roles',
        dropdown: false,
        sortOrder: 103
    },
    {
        id: 24,
        text: 'Groups Hierarchy',
        name: 'GroupsHierarchy',
        path: '/client-organization/groups-hierarchy',
        color: 'blue',
        icon: palette.flatSitemap,
        component: <GroupsHierarchy path="/client-organization/groups-hierarchy" key={24} />,
        parentId: 20,
        showRoute: true,
        showMobileRoute: false,
        permission: 'client_organization_groups_hierarchy',
        dropdown: false,
        sortOrder: 104
    },
     // EXAMPLE TICKET
     {
        id: 30,
        text: 'Example Ticket',
        name: 'ClientExampleTicket',
        path: '/client-example',
        color: 'mint',
        icon: palette.flatWeb,
        component: <ClientExampleTicket path="/client-example" key={30} />,
        parentId: null,
        showRoute: true,
        showMobileRoute: true,
        permission: 'client_example_ticket',
        dropdown: false,
        sortOrder: 11
    },
    {
        id: 31,
        text: 'Dashboard',
        name: 'ExampleTicketDashboard',
        path: '/client-example/dashboard',
        color: 'mint',
        icon: palette.flatDashboard,
        component: <ExampleTicketDashboard path="/client-example/dashboard" key={31} />,
        parentId: 30,
        showRoute: true,
        showMobileRoute: false,
        permission: 'client_example_ticket_dashboard'
    },
    {
        id: 32,
        text: "All Ticket's",
        name: 'ExampleTicketAll',
        path: '/client-example/all-tickets',
        color: 'mint',
        icon: palette.flatWeb,
        component: <ExampleTicketAll path="/client-example/all-tickets" key={32} />,
        parentId: 30,
        showRoute: true,
        showMobileRoute: false,
        permission: 'client_example_ticket_all'
    },
    {
        id: 33,
        text: 'Calendar',
        name: 'ExampleTicketCalendar',
        path: '/client-example/calendar',
        color: 'mint',
        icon: palette.flatCalendar,
        component: <ExampleTicketCalendar path="/client-example/calendar" key={33} />,
        parentId: 30,
        showRoute: true,
        showMobileRoute: false,
        permission: 'client_example_ticket_calendar'
    },
    {
        id: 34,
        text: "Client's",
        name: 'ExampleTicketClients',
        path: '/client-example/clients',
        color: 'mint',
        icon: palette.call,
        component: <ExampleTicketClients path="/client-example/clients" key={34} />,
        parentId: 30,
        showRoute: true,
        showMobileRoute: false,
        permission: 'client_example_ticket_clients'
    },
    // COST BOOK
    {
        id: 40,
        text: 'Troškovnik',
        name: 'CostBook',
        path: '/cost-book',
        color: 'yellow',
        icon: palette.flatCoins,
        component: <CostBook path="/cost-book" key={40} />,
        parentId: null,
        showRoute: true,
        showMobileRoute: true,
        permission: 'cost_book',
        dropdown: false,
        sortOrder: 10
    },
    {
        id: 41,
        text: 'Statistika',
        name: 'CostBookDashboard',
        path: '/cost-book/dashboard',
        color: 'yellow',
        icon: palette.dashboard,
        component: <CostBookDashboard path="/cost-book/dashboard" key={41} />,
        parentId: 40,
        showRoute: true,
        showMobileRoute: false,
        permission: 'cost_book_dashboard'
    },
    {
        id: 42,
        text: 'Izrada troškovnika',
        name: 'CostBookContractualCost',
        path: '/cost-book/contractual-cost',
        color: 'yellow',
        icon: palette.calculator,
        component: <CostBookContractualCost path="/cost-book/contractual-cost" key={42} />,
        parentId: 40,
        showRoute: true,
        showMobileRoute: false,
        permission: 'cost_book_contractual_cost'
    },
    {
        id: 421,
        text: 'Uređivanje troškovnika',
        name: 'CostBookContractualCostEdit',
        path: '/cost-book/cost-books/edit',
        color: 'yellow',
        icon: palette.calculator,
        component: <CostBookContractualCostEdit path="/cost-book/cost-books/edit" key={421} />,
        parentId: 40,
        showRoute: false,
        showMobileRoute: false,
        permission: 'cost_book_contractual_cost'
    },
    // COSTBOOK GUEST EDIT
    {
        id: 422,
        text: 'Izrada troškovnika',
        name: 'CostBookGuestPreEdit',
        path: '/cost-book-user-guest',
        color: 'yellow',
        icon: palette.calculator,
        component: <CostBookGuestPreEdit path="/cost-book-user-guest" key={422} />,
        parentId: null,
        showRoute: true,
        showMobileRoute: true,
        permission: 'cost_book_guest'
    },
    {
        id: 43,
        text: 'Radovi',
        name: 'CostBookConstructionWorks',
        path: '/cost-book/construction-works',
        color: 'yellow',
        icon: palette.laTools,
        component: <CostBookConstructionWorks path="/cost-book/construction-works" key={43} />,
        parentId: 40,
        showRoute: true,
        showMobileRoute: false,
        permission: 'cost_book_construction_works'
    },
    {
        id: 44,
        text: 'Novi radovi',
        name: 'CostBookNewWorks',
        path: '/cost-book/new-works',
        color: 'yellow',
        icon: palette.laPlus,
        component: <CostBookNewWorks path="/cost-book/new-works" key={44} />,
        parentId: 40,
        showRoute: true,
        showMobileRoute: false,
        permission: 'cost_book_new_works'
    },
    {
        id: 45,
        text: 'Troškovnici',
        name: 'CostBookCostBooks',
        path: '/cost-book/cost-books',
        color: 'yellow',
        icon: palette.listCheck,
        component: <CostBookCostBooks path="/cost-book/cost-books" key={45} />,
        parentId: 40,
        showRoute: true,
        showMobileRoute: false,
        permission: 'cost_book_cost_books'
    },
    {
        id: 46,
        text: 'Pregled troškovnika',
        name: 'CostBookCostBooksView',
        path: '/cost-book/cost-books/view',
        color: 'yellow',
        icon: palette.listCheck,
        component: <CostBookCostBooksView path="/cost-book/cost-books/view" key={46} />,
        parentId: 40,
        showRoute: false,
        showMobileRoute: false,
        permission: 'cost_book_cost_books_view'
    },
    {
        id: 466,
        text: 'Pregled troškovnika',
        name: 'CostBookDeliveredView',
        path: '/cost-book/cost-book-view-delivered',
        color: 'yellow',
        icon: palette.listCheck,
        component: <CostBookDeliveredView path="/cost-book/cost-book-view-delivered" key={466} />,
        parentId: 40,
        showRoute: false,
        showMobileRoute: false,
        permission: 'cost_book_cost_books_view'
    },
    {
        id: 461,
        text: 'Izrada predefiniranih troškovnika',
        name: 'CostBookPredefinedContractualCost',
        path: '/cost-book/predefined-contractual-cost',
        color: 'green',
        icon: palette.laPlus,
        component: <CostBookPredefinedContractualCost path="/cost-book/predefined-contractual-cost" key={461} />,
        parentId: 40,
        showRoute: true,
        showMobileRoute: false,
        permission: 'cost_book'
    },
    {
        id: 462,
        text: 'Predefinirani troškovnici',
        name: 'PredefinedCostBooks',
        path: '/cost-book/predefined-cost-books',
        color: 'green',
        icon: palette.calculator,
        component: <PredefinedCostBooks path="/cost-book/predefined-cost-books" key={462} />,
        parentId: 40,
        showRoute: true,
        showMobileRoute: false,
        permission: 'cost_book'
    },
    {
        id: 4622,
        text: 'Pregled troškovnika',
        name: 'CostBookPredefinedCostBooksView',
        path: '/cost-book/predefined-cost-books/view',
        color: 'yellow',
        icon: palette.listCheck,
        component: <PredefinedCostBooksView path="/cost-book/predefined-cost-books/view" key={4622} />,
        parentId: 40,
        showRoute: false,
        showMobileRoute: false,
        permission: 'cost_book_cost_books_view'
    },
    {
        id: 4623,
        text: 'Pregled dostavljenog troškovnika',
        name: 'DeliveredCostBookView',
        path: '/cost-book/delivered-cost-book-view',
        color: 'yellow',
        icon: palette.listCheck,
        component: <DeliveredCostBookView path="/cost-book/delivered-cost-book-view" key={4623} />,
        parentId: 40,
        showRoute: false,
        showMobileRoute: false,
        permission: 'cost_book'
    },
    {
        id: 463,
        text: 'Predefinirani troškovnik',
        name: 'PredefinedCostBook',
        path: '/cost-book/predefined-cost-book',
        color: 'green',
        icon: palette.laPlus,
        component: <PredefinedCostBook path="/cost-book/predefined-cost-book" key={463} />,
        parentId: 40,
        showRoute: true,
        showMobileRoute: false,
        permission: 'cost_book'
    },
    // SURVEY
    // {
    //     id: 47,
    //     text: 'Surveys',
    //     name: 'Surveys',
    //     path: '/surveys',
    //     color: 'green',
    //     icon: palette.flatList,
    //     component: <Surveys path="/surveys" key={47} />,
    //     parentId: null,
    //     showRoute: true,
    //     showMobileRoute: true,
    //     permission: 'surveys',
    //     dropdown: false,
    //     sortOrder: 15
    // },
    // {
    //     id: 48,
    //     text: 'Dashboard',
    //     name: 'SurveysDashboard',
    //     path: '/surveys/dashboard',
    //     color: 'green',
    //     icon: palette.dashboard,
    //     component: <SurveysDashboard path="/surveys/dashboard" key={48} />,
    //     parentId: 47,
    //     showRoute: true,
    //     showMobileRoute: false,
    //     permission: 'surveys'
    // },
    // {
    //     id: 49,
    //     text: 'Surveys',
    //     name: 'SurveysSurveys',
    //     path: '/surveys/surveys',
    //     color: 'green',
    //     icon: palette.questCircle,
    //     component: <SurveysSurveys path="/surveys/surveys" key={49} />,
    //     parentId: 47,
    //     showRoute: true,
    //     showMobileRoute: false,
    //     permission: 'surveys'
    // },
    // {
    //     id: 50,
    //     text: 'Add New Survey',
    //     name: 'AddNewSurvey',
    //     path: '/surveys/surveys/add-new',
    //     color: 'green',
    //     icon: palette.dashboard,
    //     component: <AddNewSurvey path="/surveys/surveys/add-new" key={50} />,
    //     parentId: 47,
    //     showRoute: false,
    //     showMobileRoute: false,
    //     permission: 'surveys'
    // },
    // {
    //     id: 51,
    //     text: 'Edit Survey',
    //     name: 'EditSurvey',
    //     path: '/surveys/surveys/edit',
    //     color: 'green',
    //     icon: palette.dashboard,
    //     component: <EditSurvey path="/surveys/surveys/edit" key={51} />,
    //     parentId: 47,
    //     showRoute: false,
    //     showMobileRoute: false,
    //     permission: 'surveys'
    // },
    // {
    //     id: 52,
    //     text: 'Drag Survey',
    //     name: 'DragSurvey',
    //     path: '/surveys/surveys/drag',
    //     color: 'green',
    //     icon: palette.dashboard,
    //     component: <DragSurvey path="/surveys/surveys/drag" key={52} />,
    //     parentId: 47,
    //     showRoute: false,
    //     showMobileRoute: false,
    //     permission: 'surveys'
    // },
    // {
    //     id: 53,
    //     text: 'View Survey',
    //     name: 'ViewSurvey',
    //     path: '/surveys/surveys/view',
    //     color: 'green',
    //     icon: palette.dashboard,
    //     component: <ViewSurvey path="/surveys/surveys/view" key={53} />,
    //     parentId: 47,
    //     showRoute: false,
    //     showMobileRoute: false,
    //     permission: 'surveys'
    // },
    // {
    //     id: 54,
    //     text: 'Fill the Survey',
    //     name: 'FillSurvey',
    //     path: '/surveys/surveys/fill',
    //     color: 'green',
    //     icon: palette.dashboard,
    //     component: <FillSurvey path="/surveys/surveys/fill" key={54} />,
    //     parentId: 47,
    //     showRoute: false,
    //     showMobileRoute: false,
    //     permission: 'surveys'
    // },
    // {
    //     id: 55,
    //     text: 'Answers Survey',
    //     name: 'AnswersSurvey',
    //     path: '/surveys/surveys/answers',
    //     color: 'green',
    //     icon: palette.dashboard,
    //     component: <AnswersSurvey path="/surveys/surveys/answers" key={55} />,
    //     parentId: 47,
    //     showRoute: false,
    //     showMobileRoute: false,
    //     permission: 'surveys'
    // },

    // { id: 56, text: 'Edit Personal', name: 'EditPersonal', path: '/surveys/surveys/EditViewAnswers/edit/personal', icon: palette.dashboard, component: <EditPersonal path="/surveys/surveys/EditViewAnswers/edit/personal" key={56} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 57, text: 'Edit Contact', name: 'EditContact', path: '/surveys/surveys/EditViewAnswers/edit/contact', icon: palette.dashboard, component: <EditContact path="/surveys/surveys/EditViewAnswers/edit/contact" key={57} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 58, text: 'Edit Interest', name: 'EditInterest', path: '/surveys/surveys/EditViewAnswers/edit/interest', icon: palette.dashboard, component: <EditInterest path="/surveys/surveys/EditViewAnswers/edit/interest" key={58} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 59, text: 'Edit Job', name: 'EditJob', path: '/surveys/surveys/EditViewAnswers/edit/job', icon: palette.dashboard, component: <EditJob path="/surveys/surveys/EditViewAnswers/edit/job" key={59} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 60, text: 'Edit Meeting', name: 'EditMeeting', path: '/surveys/surveys/EditViewAnswers/edit/meeting', icon: palette.dashboard, component: <EditMeeting path="/surveys/surveys/EditViewAnswers/edit/meeting" key={60} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    
    // { id: 61, text: 'View Personal', name: 'ViewPersonal', path: '/surveys/surveys/EditViewAnswers/view/personal', icon: palette.dashboard, component: <ViewPersonal path="/surveys/surveys/EditViewAnswers/view/personal" key={61} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 62, text: 'View Contact', name: 'ViewContact', path: '/surveys/surveys/EditViewAnswers/view/contact', icon: palette.dashboard, component: <ViewContact path="/surveys/surveys/EditViewAnswers/view/contact" key={62} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 63, text: 'View Interest', name: 'ViewInterest', path: '/surveys/surveys/EditViewAnswers/view/interest', icon: palette.dashboard, component: <ViewInterest path="/surveys/surveys/EditViewAnswers/view/interest" key={63} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 64, text: 'View Job', name: 'ViewJob', path: '/surveys/surveys/EditViewAnswers/view/job', icon: palette.dashboard, component: <ViewJob path="/surveys/surveys/EditViewAnswers/view/job" key={64} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 65, text: 'View Meeting', name: 'ViewMeeting', path: '/surveys/surveys/EditViewAnswers/view/meeting', icon: palette.dashboard, component: <ViewMeeting path="/surveys/surveys/EditViewAnswers/view/meeting" key={65} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    
    // { id: 66, text: 'Answers Personal', name: 'AnswersPersonal', path: '/surveys/surveys/EditViewAnswers/answers/personal', icon: palette.dashboard, component: <AnswersPersonal path="/surveys/surveys/EditViewAnswers/answers/personal" key={66} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 67, text: 'Answers Contact', name: 'AnswersContact', path: '/surveys/surveys/EditViewAnswers/answers/contact', icon: palette.dashboard, component: <AnswersContact path="/surveys/surveys/EditViewAnswers/answers/contact" key={67} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 68, text: 'Answers Interest', name: 'AnswersInterest', path: '/surveys/surveys/EditViewAnswers/answers/interest', icon: palette.dashboard, component: <AnswersInterest path="/surveys/surveys/EditViewAnswers/answers/interest" key={68} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 69, text: 'Answers Job', name: 'AnswersJob', path: '/surveys/surveys/EditViewAnswers/answers/job', icon: palette.dashboard, component: <AnswersJob path="/surveys/surveys/EditViewAnswers/answers/job" key={69} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 70, text: 'Answers Meeting', name: 'AnswersMeeting', path: '/surveys/surveys/EditViewAnswers/answers/meeting', icon: palette.dashboard, component: <AnswersMeeting path="/surveys/surveys/EditViewAnswers/answers/meeting" key={70} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    
    // { id: 71, text: 'Answers Edit Personal', name: 'AnswersEditPersonal', path: '/surveys/surveys/EditViewAnswers/answers/edit/personal', icon: palette.dashboard, component: <AnswersEditPersonal path="/surveys/surveys/EditViewAnswers/answers/edit/personal" key={71} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 72, text: 'Answers Edit Contact', name: 'AnswersEditContact', path: '/surveys/surveys/EditViewAnswers/answers/edit/contact', icon: palette.dashboard, component: <AnswersEditContact path="/surveys/surveys/EditViewAnswers/answers/edit/contact" key={72} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 73, text: 'Answers Edit Interest', name: 'AnswersEditInterest', path: '/surveys/surveys/EditViewAnswers/answers/edit/interest', icon: palette.dashboard, component: <AnswersEditInterest path="/surveys/surveys/EditViewAnswers/answers/edit/interest" key={73} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 74, text: 'Answers Edit Job', name: 'AnswersEditJob', path: '/surveys/surveys/EditViewAnswers/answers/edit/job', icon: palette.dashboard, component: <AnswersEditJob path="/surveys/surveys/EditViewAnswers/answers/edit/job" key={74} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 75, text: 'Answers Edit Meeting', name: 'AnswersEditMeeting', path: '/surveys/surveys/EditViewAnswers/answers/edit/meeting', icon: palette.dashboard, component: <AnswersEditMeeting path="/surveys/surveys/EditViewAnswers/answers/edit/meeting" key={75} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},

    // { id: 76, text: 'Edit All in one', name: 'EditAllInOne', path: '/surveys/surveys/EditViewAnswers/edit/all-in-one', icon: palette.dashboard, component: <EditAllInOne path="/surveys/surveys/EditViewAnswers/edit/all-in-one" key={76} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 77, text: 'Drag and Drop All in one', name: 'DragAllInOne', path: '/surveys/surveys/EditViewAnswers/drag/all-in-one', icon: palette.dashboard, component: <DragAllInOne path="/surveys/surveys/EditViewAnswers/drag/all-in-one" key={77} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 78, text: 'View All in one', name: 'ViewAllInOne', path: '/surveys/surveys/EditViewAnswers/view/all-in-one', icon: palette.dashboard, component: <ViewAllInOne path="/surveys/surveys/EditViewAnswers/view/all-in-one" key={78} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 79, text: 'Answers All in one', name: 'AnswersAllInOne', path: '/surveys/surveys/EditViewAnswers/answers/all-in-one', icon: palette.dashboard, component: <AnswersAllInOne path="/surveys/surveys/EditViewAnswers/answers/all-in-one" key={79} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},
    // { id: 80, text: 'Answers Edit All in one', name: 'AnswersEditAllInOne', path: '/surveys/surveys/EditViewAnswers/answers/edit/all-in-one', icon: palette.dashboard, component: <AnswersEditAllInOne path="/surveys/surveys/EditViewAnswers/answers/edit/all-in-one" key={80} />, parentId: 47, showRoute: false, showMobileRoute: false, permission: 'surveys'},

    // DMS
    {
        id: 86,
        text: 'DMS',
        name: 'DMS',
        path: '/dms',
        color: 'purple',
        icon: palette.flatFolder,
        component: <DMS path="/dms" key={86} />,
        parentId: null,
        showRoute: true,
        showMobileRoute: true,
        permission: 'dms',
        dropdown: false,
        sortOrder: 13,
        singleComponent: true
    },
    // PROJECTS
    {
        id: 87,
        text: 'Projects',
        name: 'Projects',
        path: '/projects',
        color: 'red',
        icon: palette.flatInterface,
        component: <Projects path="/projects" key={87} />,
        parentId: null,
        showRoute: true,
        showMobileRoute: true,
        permission: 'projects',
        dropdown: false,
        sortOrder: 12,
        singleComponent: true
    },
    {
        id: 88,
        text: 'Configurator',
        name: 'ProjectsConfigurator',
        path: '/projects-configurator',
        icon: palette.cog,
        component: <ProjectsConfigurator path="/projects-configurator" key={88} />,
        parentId: 87,
        showRoute: false,
        showMobileRoute: true,
        permission: 'projects_configurator',
        dropdown: false,
        sortOrder: null
    },
    {
        id: 90,
        text: 'Projects Group',
        name: 'ProjectsGroup',
        path: '/projects-group',
        icon: palette.flatUsers,
        component: <ProjectsGroup path="/projects-group" key={90} />,
        parentId: 87,
        showRoute: false,
        showMobileRoute: true,
        permission: 'projects_group',
        dropdown: false,
        sortOrder: null
    },
    {
        id: 89,
        text: 'Notification management',
        name: 'ProjectsNotification',
        path: '/projects-notification',
        icon: palette.laBell,
        component: <ProjectsNotification path="/projects-notification" key={89} />,
        parentId: 87,
        showRoute: false,
        showMobileRoute: true,
        permission: 'projects_notification',
        dropdown: false,
        sortOrder: null
    },
    {
        id: 91,
        text: 'Activity log',
        name: 'ProjectsActivity',
        path: '/projects-activity',
        icon: palette.history,
        component: <ProjectsActivity path="/projects-activity" key={91} />,
        parentId: 87,
        showRoute: false,
        showMobileRoute: true,
        permission: 'projects_activity',
        dropdown: false,
        sortOrder: null
    },
    {
        id: 92,
        text: 'Template management',
        name: 'ProjectsTemplate',
        path: '/projects-template',
        icon: palette.history,
        component: <ProjectsTemplate path="/projects-template" key={91} />,
        parentId: 87,
        showRoute: false,
        showMobileRoute: true,
        permission: 'projects_template',
        dropdown: false,
        sortOrder: null
    },

    // PROFIL KLETT ADMIN
    // NALOZI
    // {
    //     id: 1001,
    //     text: 'Pregled naloga',
    //     name: 'Offers',
    //     path: '/offers',
    //     color: 'green',
    //     icon: palette.flatInterface,
    //     component: <Offers path="/offers" key={1001} />,
    //     parentId: null,
    //     showRoute: true,
    //     showMobileRoute: true,
    //     permission: 'profil_offers',
    //     dropdown: false,
    //     sortOrder: 100
    // },
    // {
    //     id: 1002,
    //     text: 'Novi nalog',
    //     name: 'NewOffer',
    //     path: '/offers/new-offer',
    //     color: 'green',
    //     icon: palette.plus,
    //     component: <NewOffer path="/offers/new-offer" key={1002} />,
    //     parentId: 1001,
    //     showRoute: false,
    //     showMobileRoute: false,
    //     permission: 'profil_offers',
    //     dropdown: false,
    //     sortOrder: 100
    // },


    // // CUSTOMERS
    // {
    //     id: 1101,
    //     text: 'Pregled kupaca',
    //     name: 'CustOmers',
    //     path: '/customers',
    //     color: 'red',
    //     icon: palette.flatUsers,
    //     component: <CustOmers path="/customers" key={1101} />,
    //     parentId: null,
    //     showRoute: true,
    //     showMobileRoute: true,
    //     permission: 'profil_customers',
    //     dropdown: false,
    //     sortOrder: 100
    // },
    // // PRODUCTS
    // {
    //     id: 1201,
    //     text: 'Katalog proizvoda',
    //     name: 'Products',
    //     path: '/products',
    //     color: 'blue',
    //     icon: palette.flatCoins,
    //     component: <Products path="/products" key={1201} />,
    //     parentId: null,
    //     showRoute: true,
    //     showMobileRoute: true,
    //     permission: 'profil_products',
    //     dropdown: false,
    //     sortOrder: 100
    // },
    // {
    //     id: 1202,
    //     text: 'Proizvodi',
    //     name: 'Products',
    //     path: '/products',
    //     color: 'blue',
    //     icon: palette.flatCoins,
    //     component: <Products path="/products" key={1202} />,
    //     parentId: 1201,
    //     showRoute: true,
    //     showMobileRoute: true,
    //     permission: 'profil_products',
    //     dropdown: false,
    //     sortOrder: 100
    // },
    // {
    //     id: 1203,
    //     text: 'Lokalno skladište',
    //     name: 'ProductsLocal',
    //     path: '/products/local',
    //     color: 'blue',
    //     icon: palette.flatCoins,
    //     component: <ProductsLocal path="/products/local" key={1203} />,
    //     parentId: 1201,
    //     showRoute: true,
    //     showMobileRoute: true,
    //     permission: 'profil_products',
    //     dropdown: false,
    //     sortOrder: 100
    // },

    // // GRATIS
    // {
    //     id: 1301,
    //     text: 'Gratis nalozi',
    //     name: 'OrdersGratis',
    //     path: '/orders-gratis',
    //     color: 'yellow',
    //     icon: palette.file,
    //     component: <OrdersGratis path="/orders-gratis" key={1301} />,
    //     parentId: null,
    //     showRoute: true,
    //     showMobileRoute: true,
    //     permission: 'profil_gratis',
    //     dropdown: false,
    //     sortOrder: 100
    // }

    // PROFIL KLETT - BACKOFFICE // 1400
    {
        id: 1401,
        text: 'Pregled kupaca',
        name: 'CustomersOffice',
        path: '/customers-office',
        color: 'red',
        icon: palette.flatUsers,
        component: <CustomersOffice path="/customers-office" key={1401} />,
        parentId: null,
        showRoute: true,
        showMobileRoute: true,
        permission: 'Admin',
        dropdown: false,
        sortOrder: 100
    },
    {
        id: 1402,
        text: 'Poveži Pantheon',
        name: 'CustomersOfficeLink',
        path: '/customers-office/link-pantheon',
        color: 'green',
        icon: palette.flatInterface,
        component: <CustomersOfficeLink path="/customers-office/link-pantheon" key={1402} />,
        parentId: 1401,
        showRoute: false,
        showMobileRoute: false,
        permission: 'Admin',
        dropdown: false,
        sortOrder: 100
    },

    // PROFIL KLETT - KLIJENTI // 1500

    {
        id: 1501,
        text: 'Pregled narudžbi',
        name: 'ClientOrders',
        path: '/orders',
        color: 'green',
        icon: palette.flatInterface,
        component: <ClientOrders path="/orders" key={1501} />,
        parentId: null,
        showRoute: true,
        showMobileRoute: true,
        permission: 'B2B Sales',
        dropdown: false,
        sortOrder: 100
    },
    {
        id: 1502,
        text: 'Nova narudžba',
        name: 'NewOrder',
        path: '/orders/new-order',
        color: 'green',
        icon: palette.flatInterface,
        component: <NewOrder path="/orders/new-order" key={1502} />,
        parentId: 1501,
        showRoute: false,
        showMobileRoute: false,
        permission: 'B2B Sales',
        dropdown: false,
        sortOrder: 100
    }

    // TODO - viev/edit order
]

export default routesAndComponents;
const initialState = {
    DisplayName: "",
    email: "",
    Group: {},
    isAuthenticated: false,
    permissions: {
        // default modules
        home: true,
        help: true,
        notifications: true,
        settings: true,        
    },
    expiresIn: null,
    message: "",
    token: null,
    rememberMe: {
        checked: false,
        username: '' ,
        password: ''
    },
    language: 'en',
    languageVersion: '',
    langStrings: []
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { 
                ...action.payload, 
                isAuthenticated: true, 
                permissions: {...state.permissions, ...action.payload.permissions}, 
                rememberMe: state.rememberMe, 
                language: action.payload.language,
                languageVersion: action.payload.languageVersion,
                langStrings: state.langStrings
            }
        case 'LOGOUT':
            return { 
                ...initialState, 
                isAuthenticated: false, 
                rememberMe: state.rememberMe, 
                language: state.language,
                languageVersion: state.languageVersion,
                langStrings: state.langStrings
            }
        case 'GET_LANGUAGE_STRINGS':
            return { ...state, langStrings: action.payload }
        case 'CHANGE_LANGUAGE':
            return { ...state, language: action.payload.language, languageVersion: action.payload.version }
        case 'REMEMBER_ME':
            return { ...state, rememberMe: action.payload }
        case 'FORGET_ME':
            return { ...state, rememberMe: initialState.rememberMe }
        // PROFIL KLETT
        case 'CHANGE_USER_COMPANY_DATA':
            return { ...state, UserData: action.payload }
        default: {
            return state;
        }
    }
}

export default userReducer;